// Colors

$black: #000000;
$white: #ffffff;
$red: #e73544;
$orange: #f7a70b;
$green: #c7d530;
$blue: #51aee2;
$magenta: #d24291;

$gray: #646464;
$dark-gray: #232323;

$primary: $red;
$secondary: $gray-200;
$link: $blue;
$info: $magenta;
$success: $green;
$warning: $orange;
$danger: $red;
$dark: $dark-gray;
$light: $gray-100;


$theme-colors: ();
$theme-colors: map-merge((
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
), $theme-colors);

// Fonts

@font-face {
    font-family: "Helvetica Neue Condensed";
    src: url(../fonts/helveticaCondensed.otf);
}

// This font is not in use at the moment, left it here for reference
//
// @font-face {
//    font-family: "Helvetica Neue Roman";
//    src: url(../fonts/helveticaRoman.otf);
// }

$font-family-sans-serif: "Rubik", sans-serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: "Rubik", sans-serif;
