//
// Job
//
//

.job {
	@include transition;
	@include border-radius;
	padding: $grid-gutter-width ($grid-gutter-width / 2);
	margin: 0;
	background: rgba($black, .1);

	&:hover {
		background: rgba($black, .2);
	}

	&:not(:last-child) {
		margin-bottom: 10px!important;
	}

	> * {
		margin: 0;
	}
}
