//
// Typography
//

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
}

p, li {
    line-height: 1.75em;
}

.text-transform-none {
    text-transform: none;
}

.lead {
    font-size: 1em;
    font-weight: 400;
}

.display-1, .display-2, .display-3 {
    font-size: 2.8125rem;

    @include media-breakpoint-down(sm) {
        font-size: 2em;
    }
}
