//
// Colors
//

p, li {
    color: $gray;
}

a {
    color: $red;
}

a:hover {
    color: $red;
    text-decoration: underline;
}

.bg-primary {
    background-color: $primary ! important;
}

.bg-gray {
    background-color: $dark-gray ! important;
}

.bg-dot {
    background: $white url(../images/dotpattern.png) repeat;
}

.text-white .rich-text p {
    color: $white;
}

.text-blue {
    color: $blue ! important;
}

.text-red {
    color: $red;
}
