@charset 'UTF-8';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,700');

// Variables and functions
@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "variables-custom";
@import "bps/variables"; // BPS brand design variables

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "layout/_layout";
@import "components/_components";

// BPS brand design
@import "bps/_bps";
