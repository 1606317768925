//
// Navigation
//

// Main navigation

.navbar-nav {
    .nav-link {
		    color: $body-color!important;
        &:hover {
		        color: $body-color!important;
        }
    }
}

.navbar {
    padding: 0;
    margin: 0;

    .navbar-toggler {
        height: 56px;
    }
}


@include media-breakpoint-down(md) {
    .navbar .show {
        margin-bottom: 0.5em;
    }
}

.navbar-nav {

    & .nav-item {
        font-family: "Helvetica Neue Condensed", sans-serif;
        text-transform: uppercase;
        padding: .5rem 0 0 0;
        & button.nav-link {
            text-transform: uppercase;
        }
    }

    @include media-breakpoint-up(lg) {
        & .nav-item + .nav-item {
            margin-left: .5rem;
        }
    }
}


@include media-breakpoint-down(md) {
    .navbar-extra {
        border-top: .1rem solid $red;
        margin-top: .5rem;
    }
}

@include media-breakpoint-up(lg) {
    .navbar-extra {
        & .nav-item {
            display: inline-block;
        }

        & .nav-item:after {
            content: "|";
            color: $red;
            line-height: 1em;
            font-size: $font-size-sm;
        }

        & .nav-item:last-child:after {
            content: "";
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbar {
        & .nav-item {
            & .nav-link {
                padding: 0.5rem 0.5rem 0.3rem 0.5rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .navbar {
        & .nav-item {
            & .nav-link {
                padding: 10px 15px 5px 15px;
            }
        }
    }
}

.navbar-brand {
    margin-top: 0;
    margin-bottom: 0;
}

.dropdown-item {
    span {
        color: $red;
        font-size: $font-size-sm;
    }

    p {
        color: $dark-gray;
    }
}

.dropdown-toggle::after {
    border-top-color: $red;
    border-bottom-color: $red;
    margin-left: 0;
}

.navbar .nav-link:hover {
		background-color: rgba($black, .05);
}


// Footer navigation

.text-white .nav .nav-link {
    color: $white;
}

footer .nav {
    display: inline-block;

    & .nav-item {
        font-family: "Helvetica Neue Condensed", sans-serif;
        display: inline-block;
    }

    & .nav-link {
        float: left;
        line-height: 1em;
        padding: .5rem 0;
    }

    & .nav-item:after {
        content: "|";
        color: $red;
        float: left;
        line-height: 1em;
        padding: .5rem;
        font-size: 0.875rem;
    }

    & .nav-item:last-child:after {
        content: "";
    }
}

footer .socials a {
    padding: .5rem;
}

