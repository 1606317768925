.cta-block {
    & h3 {
        font-size: $h3-font-size;
    }
    & p {
        font-size: $font-size-sm;
    }

    & .card-link,
    & .btn {
        background-color: $red;
        color: $white;
        font-family: "Helvetica Neue Condensed", sans-serif;
        line-height: 1;
        text-transform: uppercase;
        // The bottom padding has to be smaller because of the rendering of the
        // font 'Helvetica Neue Condensed'.
        padding: 0.9375rem 1.875rem 0.7rem 1.875rem;
    }

    & .btn {
        font-size: $font-size-lg;
    }

    & .card-link {
        font-size: $font-size-sm;
    }

}
