//
// Stronger text emphasis on text-heavy pages
//

.big_text {
    .rich-text > p,
    .rich-text > ul > li
    {
        font-size: $font-size-lg;
        line-height: 1.75em;
        color: rgba(0,0,0,.84);
        font-weight: 200;
    }

    .text-white .rich-text > p {
        color: $white;
    }

    .lead .rich-text > p {
        font-weight: 400;
    }

    h1 {
        font-size: $h3-font-size;
    }

    h2 {
        font-size: $h3-font-size;
        font-weight: 400
    }

    h3 {
        font-size: $h4-font-size;
    }

    // video title
    .block-video_presenation h2 {
        font-size: $lead-font-size;
    }

    .caption .display-1 {
        font-size: $h2-font-size;
    }
}
