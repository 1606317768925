//
// Header
//

header {
    @include media-breakpoint-down(md) {
		    border-radius: 0;
		    margin: 0;
		    width: 100%;
    }
		background: $white;
}

.caption {
    // In the design, the blending mode was set to "multiply" with no opacity.
    // That renders the white text transparent instead of white. To keep the
    // text white, I chose "hard-light" with a darker shade of the red.
    mix-blend-mode: hard-light;

    .caption-text-wrap {
        background: #b30c1a;
        padding: .4em .2em 0 .2em;
        font-family: "Helvetica Neue Condensed", sans-serif;
        font-weight: 300;
        line-height: 1.5;

        // https://css-tricks.com/multi-line-padded-text/
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
    }
}

.text-white span.caption-text {
    color: $white;
}
