//
// Language chooser component in the main navigation
//

.language-chooser form {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.language-chooser button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}
